import React from "react"
import { css } from "@emotion/core"

export default () => {
  const home = require("../cms/home")
  
  return (
    <footer
      css={css`
        border-top: 2px solid var(--text-100);
        padding-top: 24px;
        margin-top: 64px;
      `}
    >
      <p
        css={css`
          white-space: pre-wrap;
          line-height: 1.7;
          color: var(--text-300);
          margin: 0;
        `}
      >
        {home.footer}
      </p>
    </footer>
  )
}