import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"
import Img from "gatsby-image"

import Template from "../components/Template"
import Markdown from "../components/Markdown"
import Footer from "../components/Footer"

export default () => {
  const data = require("../cms/home")

  const query = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 400, quality: 100 ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const portrait = query.images.edges.find(edge => edge.node.relativePath === data.portrait.replace("/assets/", "")).node.childImageSharp.fluid

  return (
    <Template>
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr 2fr;
          grid-column-gap: 64px;

          @media (max-width: 768px) {
            grid-template-columns: 1fr;
            grid-row-gap: 32px;

            > .gatsby-image-wrapper {
              grid-row: 2;
            }
          }
        `}
      >
        <Img
          fluid={portrait}
          css={css`
            border-radius: 8px;
          `}
        />

        <Markdown raw={data.text} fontSize="1.15rem" />
      </div>

      <Footer />
    </Template>
  )
}